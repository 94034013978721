#root {
  display: flex;
  justify-content: center;
}

html {
  overflow-y: scroll;
}

.screen {
  width: 100%;
  height: 100%;
}

@keyframes spinner-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner {
  border-right-color: transparent;
  animation: spinner-rotate 1s linear infinite;
}

.scrollable::-webkit-scrollbar {
  width: 8px;
  padding: 0 4px;
}

.scrollable::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.scrollable::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border-left: 0;
  border-right: 0;
  background-color: #9d9ca1;
}

input[type="radio"] {
  cursor: pointer;
  appearance: none;
  background-color: white;
  width: 1.2em;
  height: 1.2em;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
input[type="radio"]::before {
  content: "";
  width: 0.8em;
  height: 0.8em;
  border-radius: 100%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #abfe2c;
  border: 2px solid #00501e;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

.full-container {
  margin-left: calc(-100vw / 2 + 500px / 2);
  margin-right: calc(-100vw / 2 + 500px / 2);
}

select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background-color: #fafafa;
  height: 45px;
  width: 100%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);
  background-position: 100%;
  background-repeat: no-repeat;
  border: 1px solid #ccc;
  padding: 0.5rem;
  border-radius: 0;
}
